import React, { useEffect } from "react";
import osLogo from "@assets/webp/pepe-os-wide.webp";
import navHistory from "@assets/jpg/History_256.jpg";
import navMessenger from "@assets/jpg/Messenger_256.jpg";
import { PepeOSTourSC } from "./PepeOSTour.styled";
import { setExplorerNavState } from "@store/userInterface/userInterface";
import { addAppWithSettings } from "@store/osState/pepeOSThunk";
import { EAppKeys } from "../appState/EAppKeys";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { EExplorerNavState } from "../InternetExplorer";
import basedIcon from "@assets/webp/based-pixel.webp";
import kekbotLogo from "@assets/webp/kekbot-icon.webp";
import researchIcon from "@assets/webp/research-icon.webp";

const PepeOSTour: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.setItem("tutorialComplete", "true");
  }, []);

  const navigateToExplorerPage = (page: EExplorerNavState) => {
    dispatch(setExplorerNavState(page));
    dispatch(addAppWithSettings(EAppKeys.Explorer));
  };

  return (
    <PepeOSTourSC>
      <div className="tour_container">
        <div className="tour_content">
          <div className="intro_body">
            <img src={osLogo} alt="Pepe OS" />
            <span>
              Thank you for Installing PepeCoin's PepeOS Original Edition.
              <br />
              <br />
              Here at PepeCoin, we're dedicated to not only building cool stuff,
              but also crafting an atmosphere where creativity thrives and true
              memetic power flows through all those who participate within it.
              <br />
              <br />
              Feel free to explore PepeOS at your own pace by closing/minimizing
              this window, or embark on a guided tour with the options on the
              right. Welcome home, fren.
              <br />
              <br />
              <button
                onClick={() =>
                  window.open(
                    "https://app.uniswap.org/tokens/ethereum/0xa9e8acf069c58aec8825542845fd754e41a9489a",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                Buy Pepecoin
              </button>
              <br />
              <br />
              Contract Address:
              <br />
              <a
                href="https://etherscan.io/token/0xa9e8acf069c58aec8825542845fd754e41a9489a"
                rel="noopener noreferrer"
                target="_blank"
                className="ca"
              >
                0xa9e8acf069c58aec8825542845fd754e41a9489a
              </a>
            </span>
          </div>
          <div className="nav">
            {/* <div>
              <button
                onClick={() => {
                  dispatch(setIsGuest(null));
                  navigateToExplorerPage(EExplorerNavState.About);
                }}
                className="nav_title"
                id="start_here"
              >
                <img src={navStart} alt="Getting Started" />
                Getting Started
              </button>
              <p>
                To get the most out of your PepeOS experience, logging in is
                recommended. However, you can explore the OS without using its
                integrated features as a Guest. To log in, select the 'Log Out'
                option from the Start Bar and choose one of the options in the
                Connect Wallet menu.
              </p>
            </div> */}
            <div>
              <button
                onClick={() =>
                  navigateToExplorerPage(EExplorerNavState.History)
                }
                className="nav_title"
                id="best"
              >
                <img src={navHistory} alt="History" />
                History
              </button>
              <p>
                PepeOS is powered by the PepeCoin project which was originally
                launched in 2016 by a group of OG crypto enthusiasts, miners,
                and buildooors. Click the history icon above to learn more about
                PepeCoin's rich history via the Pepe Explorer application.
              </p>
            </div>

            <div>
              <button
                onClick={() => dispatch(addAppWithSettings(EAppKeys.Messenger))}
                className="nav_title"
                style={{ color: "#4267B2" }}
              >
                <img src={navMessenger} alt="Messenger" />
                Messenger
              </button>
              <p>
                PepeCoin's live messenger is a decentralized E2E encrypted
                instant messaging application with a retro interface for fun and
                secure wallet-to-wallet communication.
              </p>
            </div>
            <div>
              <a
                href="https://kek.bot"
                className="nav_title"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#54aa2b" }}
              >
                <img src={kekbotLogo} alt="Kek Bot" />
                Kek Bot
              </a>
              <p>
                Kek Bot is an advanced trading engine that converts natural
                language into complex automated trading strategies. Kek Bot is
                like a quant in your pocket, empowering even the most smooth
                brained ape to be among the most gigabrained traders. You can
                even earn rewards for training Kek Bot via "Prompt Mining".
              </p>
            </div>
            <div>
              <a
                href="https://getbased.ai"
                className="nav_title"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "gray" }}
              >
                <img src={researchIcon} alt="Based AI" />
                BasedAI
              </a>
              <p>
                BasedAI is a custom built Layer 1 blockchain network built by
                the gigabrains at Pepecoin and Based Labs. The network is
                purpose-built for zk-LLMs and smart contracts -- bringing forth
                a future for decentralized, private, and censorship resistant
                AI.
              </p>
            </div>
            <div>
              <button
                onClick={() => dispatch(addAppWithSettings(EAppKeys.BasedFarm))}
                className="nav_title"
                style={{ color: "#FD673A" }}
              >
                <img src={basedIcon} alt="Messenger" />
                Staking
              </button>
              <p>
                Click the head icon to stake your Pepecoin and earn BasedAI
                rewards by using the built-in farming/bridging application.
              </p>
            </div>
          </div>
        </div>
        <footer>
          &copy; 2016-{new Date().getFullYear()} PepeCoin.
          <br /> All rights reserved.
        </footer>
      </div>
    </PepeOSTourSC>
  );
};

export default PepeOSTour;
