import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  useAddress,
  useContract,
  useContractRead,
  getBlockNumber,
} from "@thirdweb-dev/react";
import basedAbi from "./basedAbi.json";
import { ethers } from "ethers";
import { BasedFarmSC } from "./BasedFarm.styled";
import Prebridge from "./Prebridge";
import pepeStar from "@assets/pepeOS-icons/Pepe_Star.png";
import prebridgeAbi from "./prebridgeABI.json";
import { useAppDispatch } from "@hooks/useAppDispatch";
import BasedStaking from "./BasedStaking";
import pepecoinLogo from "@assets/png/pepecoin-logo.png";
import basedBrain from "@assets/webp/based-pixel.webp";

const stakingCA = "0xA6B816010Ab51e088C4F19c71ABa87E54b422E14";

const pepecoinCA = "0xA9E8aCf069C58aEc8825542845Fd754e41a9489A";

const prebridgeCA = "0x40359B38db010A1d0ff5E7d00CC477D5b393bd72";

const pid = 0;

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

const BasedFarm: FC = () => {
  // const [depositAmount, setDepositAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [stakedAmountInPool, setStakedAmountInPool] = useState("0");
  const [credits, setCredits] = useState("0");
  const [creditsEarned, setCreditsEarned] = useState("0");
  const [dummyState, setDummyState] = useState(0);
  const [balanceUpdateKey, setBalanceUpdateKey] = useState(0);
  // const [isPrebridging, setIsPrebridging] = useState(false);
  const [activeTab, setActiveTab] = useState<"stake" | "bridge" | "about">(
    "bridge"
  );
  const [blockNumber, setBlockNumber] = useState<number | null>(null);

  const dispatch = useAppDispatch();

  const address = useAddress();

  const { contract: stakingContract } = useContract(prebridgeCA, prebridgeAbi);

  const { data: totalCredits, isLoading: totalCreditsLoading } =
    useContractRead(stakingContract, "calculateTotalCredits", [address]);

  useEffect(() => {
    if (totalCredits && totalCredits._hex) {
      // Convert the hex string to a BigInt
      const rewardBigInt = BigInt(totalCredits._hex);
      // Format the BigInt as ether
      setCreditsEarned(ethers.utils.formatUnits(rewardBigInt, 18));
    }
  }, [totalCredits, address, dummyState]);

  useEffect(() => {
    const fetchBlockNumber = async () => {
      const number = await getBlockNumber({ network: "ethereum" });
      setBlockNumber(number);
    };

    fetchBlockNumber();

    const intervalId = setInterval(fetchBlockNumber, 13000);

    return () => clearInterval(intervalId);
  }, []);

  const { contract } = useContract(stakingCA, basedAbi);

  const { data: stakedAmount, isLoading: stakedAmountIsLoading } =
    useContractRead(contract, "userInfo", [pid, address]);

  useEffect(() => {
    if (stakedAmount && stakedAmount.amount) {
      // Convert the hex string to a BigInt
      const stakedBigInt = BigInt(stakedAmount.amount);
      // Format the BigInt as ether
      setStakedAmountInPool(ethers.utils.formatUnits(stakedBigInt, 18));
    }
  }, [stakedAmount, address, dummyState]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDummyState((prev) => prev + 1);
    }, 15000);

    return () => clearInterval(intervalId);
  }, []);

  const refreshRewards = useCallback(() => {
    setDummyState((prev) => prev + 1);
  }, []);

  useInterval(refreshRewards, 13000);

  useEffect(() => {
    if (address) {
      // Fetch balances and rewards when the address changes
      setDummyState((prev) => prev + 1);
    } else {
      // Reset state when the address is disconnected
      setStakedAmountInPool("N/A");
    }
  }, [address]);

  return (
    <BasedFarmSC>
      <div className="banner">
        <h3>
          BasedAI
          <br />
          Pre-Bridge
        </h3>
      </div>
      <menu role="tablist">
        <button
          aria-selected={activeTab === "bridge"}
          aria-controls="bridge"
          onClick={() => setActiveTab("bridge")}
          style={{ display: "flex", gap: "5px", alignItems: "center" }}
        >
          <img
            style={{
              height: "1rem",
            }}
            src={pepecoinLogo}
          />{" "}
          $PEPECOIN
        </button>
        <button
          aria-selected={activeTab === "stake"}
          aria-controls="stake"
          onClick={() => setActiveTab("stake")}
        >
          <img
            style={{
              height: "1rem",
            }}
            src={basedBrain}
          />{" "}
          $BASED
        </button>

        <button
          aria-selected={activeTab === "about"}
          aria-controls="about"
          onClick={() => setActiveTab("about")}
        >
          About BasedAI
        </button>
      </menu>
      <article
        role="tabpanel"
        id="stake"
        hidden={activeTab === "about" || activeTab === "bridge"}
      >
        <BasedStaking />
      </article>
      <article
        role="tabpanel"
        hidden={activeTab === "about" || activeTab === "stake"}
        id="bridge"
      >
        <Prebridge />
      </article>
      <article
        role="tabpanel"
        hidden={activeTab === "bridge" || activeTab === "stake"}
        id="about"
      >
        <p style={{ marginBottom: "1rem" }}>
          BasedAI is a custom engineered Layer 1 network being developed by the
          gigabrains at PepeCoin and Based Labs.
          <br />
          <br />
          BasedAI is a peer-to-peer network for zk-LLMs (Zero Knowledge Large
          Language Models) with full EVM (smart contract) capabilities. The
          network weaves the decentralized storage and computation of open
          source AI models directly into it's consensus mechanism and enables
          users to interact with in a fully privacy-preserving manner.
          <br />
          <br />
          For the layman, BasedAI is a privacy-preserving decentralized AI
          network that democratizes the use and monetization of AI models,
          ushering in a new era of AI freedom that is free of the shackles
          imposed on traditional models by centralized tech overlords.
          <br />
          <br />
          BasedAI is currently undergoing a public testnet phase, and will be
          transitioning to mainnet soon. To participate in the testnet visit{" "}
          <a
            target="_blank"
            href="https://getbased.ai"
            rel="noopener noreferrer"
          >
            {"https://getbased.ai"}
          </a>
          <br />
          <br />
          We look forward to you all joining us on this journey.
          <br />
          <br />
          CA: 0x44971abf0251958492fee97da3e5c5ada88b9185
        </p>
      </article>
      <section className="tabs">
        <article role="tabpanel">
          <fieldset>
            <legend>Total Rewards</legend>
            <div className="controls">
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <img
                  style={{ height: "1rem" }}
                  src={pepeStar}
                  alt="pepe-star"
                />
                <p>
                  {" "}
                  {totalCreditsLoading ? (
                    "Loading..."
                  ) : (
                    <>
                      Mainnet $BASED Earned:{" "}
                      {parseFloat(creditsEarned).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 4,
                      })}
                    </>
                  )}
                </p>
              </div>
            </div>
          </fieldset>
        </article>
      </section>

      <section
        className="field-row"
        style={{ justifyContent: "space-between" }}
      >
        <p style={{ textAlign: "left", fontSize: "0.75rem" }}>
          Forgot to withdraw from the V1 farm?{" "}
          <a
            href="https://forms.simple.ink/pepecoin-v1-pool-claim"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>
        </p>
        <button
          onClick={() => window.open("https://www.getbased.ai", "_blank")}
          style={{ minWidth: "110px" }}
        >
          About BasedAI
        </button>
      </section>
    </BasedFarmSC>
  );
};

export default BasedFarm;

// https://forms.simple.ink/pepecoin-v1-pool-claim
