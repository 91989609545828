import React, { useState, useRef, useMemo, useEffect } from "react";
import { Client, useClient, useCanMessage } from "@xmtp/react-sdk";
import { useDispatch } from "react-redux";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import type { ClientOptions, Signer } from "@xmtp/react-sdk";
import type { TETHAddress } from "../helpers";
import { loadKeys, storeKeys, throttledFetchAddressName } from "../helpers";
import { setClientAvatar, setClientName } from "../store/xmtp/xmtp.slice";

type TClientStatus = "new" | "created" | "enabled";

type TResolveReject<T = void> = (value: T | PromiseLike<T>) => void;

const makePromise = <T = void>() => {
  let reject: TResolveReject<T> = () => {};
  let resolve: TResolveReject<T> = () => {};
  const promise = new Promise<T>((yes, no) => {
    resolve = yes;
    reject = no;
  });
  return {
    promise,
    reject,
    resolve,
  };
};

const clientOptions = {
  env: "production",
} as Partial<ClientOptions>;

const useInitXmtpClient = () => {
  const walletClientRef = useRef<Signer | null>();
  const [status, setStatus] = useState<TClientStatus | undefined>();
  const [signing, setSigning] = useState(false);
  const dispatch = useDispatch();
  const walletClient = useSigner();
  const address = useAddress();

  const { client, isLoading, initialize } = useClient();
  const { canMessageStatic: canMessageUser } = useCanMessage();

  useEffect(() => {
    if (!client) {
      setStatus(undefined);
    }
  }, []);

  const initializeClient = async () => {
    // skip this if we already have a client and ensure we have a walletClient
    if (!client && walletClient && address) {
      setSigning(true);
      try {
        let keys = loadKeys(address);
        // check if we already have the keys
        console.log("keys", keys);
        if (keys) {
          // resolve client promises
          createResolve();
          enableResolve();
          // no signatures needed
          setStatus("enabled");
        } else {
          // no keys found, but maybe the address has already been created
          // let's check
          const canMessage = await canMessageUser(address, clientOptions);
          if (canMessage) {
            // resolve client promise

            enableResolve();
            // identity has been created
            setStatus("created");
          } else {
            createResolve();
            enableResolve();
            // no identity on the network
            setStatus("new");
          }
          keys = await Client.getKeys(walletClient, {
            ...clientOptions,
            skipContactPublishing: true,
            persistConversations: false,
            preCreateIdentityCallback,
            preEnableIdentityCallback,
          });
          storeKeys(address, keys);
          setStatus("enabled");
        }

        const xmtpClient = await initialize({
          keys,
          options: clientOptions,
          signer: walletClient,
        });
        if (xmtpClient) {
          const name = await throttledFetchAddressName(
            xmtpClient.address as TETHAddress
          );
          if (name) {
            const avatar = `https://effigy.im/a/${name}.svg`;
            dispatch(setClientAvatar(avatar));
            dispatch(setClientName(name));
          }
        } else {
          console.log("No chat client found");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSigning(false);
      }
    }
  };

  const { enableResolve, preEnableIdentityCallback, resolveEnable } =
    useMemo(() => {
      const { promise: enablePromise, resolve } = makePromise();

      return {
        enableResolve: resolve,
        // this is called right after signing the create identity signature
        preEnableIdentityCallback: () => {
          setSigning(false);
          setStatus("created");
          return enablePromise;
        },
        // executing this function will result in displaying the enable account
        // signature prompt
        resolveEnable: () => {
          enableResolve();
          setSigning(true);
        },
      };
    }, [initializeClient, walletClient]); // Add initializeClient as a dependency

  // create promise, callback, and resolver for controlling the display of the
  // enable account signature.
  const { createResolve, preCreateIdentityCallback, resolveCreate } =
    useMemo(() => {
      const { promise: createPromise, resolve } = makePromise();
      return {
        createResolve: resolve,
        preCreateIdentityCallback: () => createPromise,
        // executing this function will result in displaying the create account
        // signature prompt
        resolveCreate: () => {
          createResolve();
          setSigning(true);
        },
      };
      // if the walletClient changes during the onboarding process, reset the promise
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletClient]); // Add enableResolve as a dependency

  useEffect(() => {
    const checkClient = async () => {
      if (!client && walletClient && address) {
        try {
          let keys: Uint8Array | undefined = loadKeys(address);
          if (keys) {
            setStatus("enabled");
          } else {
            const canMessage = await canMessageUser(address, clientOptions);
            if (canMessage) {
              setStatus("created");
            } else {
              setStatus("new");
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    void checkClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletClient, client, status]);

  useEffect(() => {
    walletClientRef.current = walletClient;
  }, [walletClient]);

  return {
    client,
    isLoading: isLoading || signing,
    resolveCreate,
    resolveEnable,
    status,
    setStatus,
    signing,
    initializeClient,
  };
};

export default useInitXmtpClient;
